<template>
  <v-card class="teros-elevation" :color="tableColor">
    <v-card-title class="px-5 pb-0 v-title-cadastro">
      <span class="h5 font-weight-bold">
        Projeto: <span class="">{{ projeto.titulo }}</span>
      </span>
    </v-card-title>
    <MasterDetail
      ref="masterDetail"
      :canDelete="!hasSelection"
      :canEdit="!hasSelection"
      :cols="cols"
      :confirmDeleteMessage="confirmDeleteMessage"
      :contextOptions="contextOptions"
      :customResource="customResource"
      :hasExportCSV="false"
      :hasExportXLSX="false"
      :hasMonthlyFilter="true"
      :opts="opts"
      :selection.sync="selection"
      selectionCol
    >
      <DropdownCaptacao />
    </MasterDetail>
    <FormModal
      :cols="fields"
      :opened.sync="updateModalOpened"
      title="Edição em lote"
      :value.sync="updateModalData"
      @save="doMassUpdate"
    />
    <Modal
      ref="confirmModal"
      appendTitleIcon="mdi-alert-circle-outline"
      title="Exclusão"
      width="300px"
    >
      <p>{{ confirmModalMessage }}</p>
      <template #buttons="{ close }">
        <v-spacer></v-spacer>
        <v-btn color="secondary" dark depressed @click="close(false)">
          Não
        </v-btn>
        <v-btn color="primary" depressed @click="close(true)">
          Sim
        </v-btn>
      </template>
    </Modal>
  </v-card>
</template>

<script>
import MasterDetail from "@/components/master-detail.vue";
import toMonth from "@/helpers/toMonth";
import { mapGetters } from "vuex";
import { toFinite } from 'lodash';

export default {
  // props: {},
  components: {
    DropdownCaptacao: () => import("@/components/dropdown-captacao.vue"),
    FormModal: () => import("@/components/form-modal.vue"),
    MasterDetail,
    Modal: () => import("@/components/g-modal.vue")
  },
  // mixins: [],
  // directives: {},
  data() {
    return {
      projeto: {
        titulo: ""
      },
      selection: [],
      updateModalData: {},
      updateModalOpened: false,
      opts: {
        colaboradores: []
      }
    };
  },
  computed: {
    ...mapGetters(["clientId"]),
    cols() {
      return [
        {
          key: "matricula",
          name: "Matricula",
          hideInform: true
        },
        {
          key: "funcionario_id",
          name: "Matricula",
          type: this.$fieldTypes.AUTOCOMPLETE,
          rel: { to: "colaboradores", key: "id", name: "matricula" },
          colSize: 6,
          editable: false,
          hideInTable: true
        },
        {
          key: "funcionario_id",
          name: "Colaborador",
          type: this.$fieldTypes.AUTOCOMPLETE,
          rules: [{ rule: "required" }],
          rel: { to: "colaboradores", key: "id", name: "nome" },
          colSize: 6,
          filterable: true,
          hideInTable: true
        },
        {
          key: "nome",
          name: "Colaborador",
          type: this.$fieldTypes.AUTOCOMPLETE,
          rules: [{ rule: "required" }],
          colSize: 6,
          filterable: true,
          hideInform: true
        },
        {
          key: "competencia",
          name: "Competência",
          type: this.$fieldTypes.MONTH,
          colSize: 6,
          filterable: true
        },
        {
          key: "percentual",
          name: "Dedicação (%)",
          type: this.$fieldTypes.TEXT,
          rules: [{ rule: "required" }],
          colSize: 6,
          filterable: true
        },
        {
          key: "totalCustoFunc",
          name: "Total custo func.",
          type: this.$fieldTypes.MONEY,
          rules: [{ rule: "required" }],
          hideInform: true,
          colSize: 6
        },
        {
          key: "custoPED",
          name: "Custo P&D.",
          type: this.$fieldTypes.MONEY,
          rules: [{ rule: "required" }],
          hideInform: true,
          colSize: 6
        },
        {
          key: "custoINSS",
          name: "Custo INSS",
          type: this.$fieldTypes.MONEY,
          rules: [{ rule: "required" }],
          hideInform: true,
          colSize: 6
        },
        {
          key: "encargoINSS",
          name: "Encargo INSS (%)",
          type: this.$fieldTypes.TEXT,
          rules: [{ rule: "required" }],
          hideInform: true,
          colSize: 6
        },
        {
          key: "custoFGTS",
          name: "Custo FGTS",
          type: this.$fieldTypes.MONEY,
          rules: [{ rule: "required" }],
          hideInform: true,
          colSize: 6
        },
        {
          key: "encargoFGTS",
          name: "Encargo FGTS (%)",
          type: this.$fieldTypes.TEXT,
          rules: [{ rule: "required" }],
          hideInform: true,
          colSize: 6
        }
      ];
    },
    confirmModalMessage() {
      if (!this.hasSelection) {
        return "";
      }

      return `Deseja excluir ${this.selection.length} registros?`;
    },
    contextOptions() {
      if (!this.hasSelection) {
        return [];
      }

      return [
        {
          name: "Editar selecionados",
          cb: () => {
            this.updateModalData = { percentual: 0 };
            this.updateModalOpened = true;
          }
        },
        {
          name: "Excluir selecionados",
          cb: async () => {
            const response = await this.$refs.confirmModal.asyncOpen();

            if (response) {
              try {
                const url = `/v1/captacao/${this.clientId}/timesheets/${this.projetoId}/multiple/delete`;
                const timesheetIds = this.selection.map(({ id }) => id);
                await this.api().delete(url, { data: { timesheetIds } });
                this.$notify({
                  group: "geral",
                  duration: 15000,
                  type: "sucess",
                  title: "Exclusão concluída",
                  text: `Foi excluido ${timesheetIds.length} registros.`
                });
              } catch (error) {
                this.notify(error);
              }
            }
          }
        }
      ];
    },
    customResource() {
      const resource = this.apiResource(
        `/v1/captacao/${this.clientId}/timesheets/${this.projetoId}`
      );
      return {
        ...resource,
        get(args) {
          return resource.get({ ...args }).then(result => {
            return result;
          });
        },
        save(data) {
          const timesheets = {
            id: data.id || null,
            funcionario_id: data.funcionario_id,
            percentual: data.percentual,
            competencia: data.competencia
          };
          return resource.save(timesheets, timesheets.id).then(result => {
            return result;
          });
        }
      };
    },
    editModalMessage() {
      if (!this.hasSelection) {
        return "";
      }

      return `${this.selection.length} registros serão alterados.`;
    },
    fields() {
      return [
        {
          key: "none",
          name: `${this.selection.length} registros serão alterados.`,
          type: this.$fieldTypes.SUBTITLE,
        },
        {
          key: "dedicacao",
          name: "Dedicação (%)",
          type: this.$fieldTypes.NUMBER,
          rules: [{ rule: "required" }, { rule: "between", params: { min: 0, max: 100 } }],
          defaultValue: 0,
        }
      ];
    },
    hasSelection() {
      return this.selection.length > 1;
    },
    projetoId() {
      return this.$route.params.id;
    }
  },
  // filters: {},
  created() {
    this.getProjetoSelecionado();
    this.getColaboradores();
  },
  // mounted() {},
  // updated() {},
  // destroyed() {},
  methods: {
    confirmDeleteMessage(row) {
      const { competencia, matricula, nome } = row;
      return `Deseja excluir o registro de ${
        matricula ? `${matricula} — ` : ""
      }${nome} para competência ${toMonth(competencia)}?`;
    },
    async doMassUpdate(data, closeModal) {
      try {
        const dedicacao = toFinite(data.dedicacao ?? 0);
        const url = `/v1/captacao/${this.clientId}/timesheets/${this.projetoId}/multiple`;
        const timesheetIds = this.selection.map(({ id }) => id);
        await this.api().post(url, { dedicacao, timesheetIds });
        this.$notify({
          group: "geral",
          duration: 15000,
          type: "sucess",
          title: "Edição concluída",
          text: `Foram alterados ${timesheetIds.length} registros.`
        });
        closeModal();
        this.$refs.masterDetail.doLoad();
      } catch (error) {
        this.notify(error);
      }
    },
    getProjetoSelecionado() {
      return this.apiResource(
        `/v1/captacao/projetos/${this.clientId}/${this.projetoId}`
      )
        .get()
        .then(result => {
          return (this.projeto = result);
        });
    },
    getColaboradores() {
      return this.apiResource(`/v1/rh/${this.clientId}/colaboradores`)
        .get()
        .then(result => {
          return (this.opts.colaboradores = result.data || []);
        });
    }
  },
  watch: {
    clientId() {
      this.$router.push({ name: "projetos-captacao" });
    }
  }
};
</script>
